<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        outlined
        color="primary"
        v-bind="attrs"
        v-on="on"
        class="mr-2 btn-fix action-btn"
      >
        Instructions
        <v-icon right dark>mdi-video-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5">
        Learn How to Create a Seminar
      </v-card-title>

      <v-card-text>
        Coming Soon!
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.btn-fix:focus::before {
  opacity: 0 !important;
}

@media only screen and (max-width: 600px) {
  .action-btn {
    margin-top: 2px;
    margin-bottom: 2px;
    width: 100%;
  }
}
</style>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
