<template>
  <v-data-table
    :headers="headers"
    :items="seminars"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="ma-4 elevation-4 row-pointer"
    hide-default-footer
    disable-pagination
    :loading="loading"
    @click:row="handleClick"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Campaigns</v-toolbar-title>
        <v-spacer></v-spacer>
        <instructions-dialog></instructions-dialog>
        <v-btn
          color="primary"
          href="/downloads/Checklist_v2.pdf"
          class="mr-2 action-btn"
          target="_blank"
          download
          outlined
        >
          Checklist
          <v-icon right dark>mdi-cloud-download-outline</v-icon>
        </v-btn>
        <!-- <v-btn
          color="primary"
          href="/downloads/AnnuityCheck_v26.pptx"
          class="mr-2 action-btn"
          target="_blank"
          download
          outlined
        >
          Powerpoint V26<v-icon right dark> mdi-cloud-download-outline</v-icon>
        </v-btn> -->
        <!-- <v-btn
          color="primary"
          href="/downloads/Checklist_v2.pdf"
          class="mr-2 action-btn"
          target="_blank"
          download
          outlined
        >
          Transcript
          <v-icon right dark>mdi-cloud-download-outline</v-icon>
        </v-btn> -->

        <v-btn class="action-btn" color="primary" to="/seminar/create"
          >Create Campaign</v-btn
        >
      </v-toolbar>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.requested_count="{ item }">
      {{ item.requested_count.toLocaleString() }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.registered="{ item }">
      <div v-for="(event, index) in item.events" :key="index">
        {{ event.count }} / {{ event.capacity }}
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.date="{ item }">
      <div v-for="(event, index) in item.events" :key="index">
        {{ formatDate(event.start_date) }} - {{ event.start_time }}
      </div>
    </template>
  </v-data-table>
</template>

<style scoped>
@media only screen and (max-width: 1000px) {
  .v-toolbar /deep/ .v-toolbar__content {
    flex-wrap: wrap !important;
    height: unset !important;
  }
  .v-toolbar {
    flex-wrap: wrap !important;
    height: unset !important;
  }
}
</style>

<script>
import hasuraService from "@/services/hasura.service.js";
import dayjs from "dayjs";
import InstructionsDialog from "@/components/Seminar/InstructionsDialog.vue";

export default {
  mixins: [hasuraService],
  components: {
    InstructionsDialog,
  },
  async mounted() {
    this.loading = true;
    this.seminars = (await this.campaignList(this.$store.state.user.id)).map(
      (c) => {
        let events = c.events.map((e) => {
          return {
            start_date: e.start_date,
            start_time: e.start_time,
            capacity: e.capacity,
            count: e.households.reduce((a, b) => {
              return a + b.people_aggregate.aggregate.count;
            }, 0),
          };
        });
        return {
          id: c.id,
          venue_name: c.venue_name,
          city: c.city,
          state: c.state,
          requested_count: c.requested_count,
          date: c.events[0].start_date,
          events: events,
          registered: events.reduce((currentVal, event) => {
            return currentVal + event.count;
          }, 0),
        };
      }
    );
    this.loading = false;
  },
  methods: {
    handleClick(e) {
      this.$router.push(`/seminar/${e.id}`);
    },
    formatDate(date) {
      return dayjs(date).format("M/D/YY");
    },
  },
  data() {
    return {
      loading: false,
      sortBy: "date",
      sortDesc: true,

      headers: [
        {
          text: "Venue",
          value: "venue_name",
        },
        { text: "City", value: "city" },
        { text: "State", value: "state" },
        { text: "Mailed", value: "requested_count" },
        { text: "Registered", value: "registered" },
        { text: "Date", value: "date" },
      ],
      seminars: [],
    };
  },
};
</script>
